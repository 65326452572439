<template>
  <r-container>
    <r-meta title="verify" description="verify of peyments"></r-meta>
    <r-row class="h-center">
      <r-col
        class="col-12 text-center"
        :class="{
          'color-error': status !== 'ok',
          'color-success': status === 'ok',
        }"
      >
        <div>{{ $t("payment_status_" + status) }}</div>
        <r-divider class="my-2 color-white" v-if="status === 'ok'"></r-divider>
        <div class="col-12 text-center" v-if="status === 'ok'">
          {{ $t(["payment_status_ref", [refId]]) }}
        </div>
      </r-col>
    </r-row>
  </r-container>
</template>

<script>
export default {
  name: "paymentVerify",
  props: {
    status: String,
    refId: String,
  },
  created() {
    if (this.status === "ok") {
      this.$r.store.cart = {};
      this.$r.store.freeCart = {};
      this.$storage.set("carts", this.$r.store.cart);
      this.$storage.set("freeCarts", this.$r.store.freeCart);
    }
  },
};
</script>
